import React, { useEffect, useState } from 'react';
import { useIntl } from 'context/IntlContext';
import { Text, Box, Container, Divider, Heading } from 'components';
import Seo from '../../components/SEO';
import {
  keyForTranslationsTermsAndConditions,
} from 'utils/get-translations';

const TermsAndConditions = () => {
  const { t } = useIntl();
  const [title] = useState('termsAndConditionsPage.title');
  const [termsAndConditions, _setTermsAndConditions] = useState(
    keyForTranslationsTermsAndConditions().keys,
  );
  // useEffect(() => {
  //   if (locale === 'es') {
  //     setTitle(keyForTranslationsTermsAndConditions('spanish').title);
  //   }
  //   if (locale === 'en') {
  //     setTitle(keyForTranslationsTermsAndConditions('english').title);
  //   }
  //   if (locale === 'it') {
  //     setTitle(keyForTranslationsTermsAndConditions('italian').title);
  //   }
  //   if (locale === 'pt') {
  //     setTitle(keyForTranslationsTermsAndConditions('portuguese').title);
  //   }
  // }, [locale]);
  return (
    <>
      <Seo title={t('global.meta.title')} description={''} />
      <Box>
        <Container>
          <Heading
            as="h2"
            sx={{
              color: 'quaternary',
              mb: 7,
              fontWeight: 700,
            }}
          >
            {t(title)}
          </Heading>
          <Divider />
          {termsAndConditions.map((term, index) => (
            <div key={index}>
              <Text
                as="p"
                sx={{
                  variant: 'text.underHero',
                  color: 'quaternary',
                  mb: 7,
                  fontWeight: '700',
                }}
              >
                {t(term.title)}
              </Text>
              <Text
                as="p"
                variant="underHero"
                dangerouslySetInnerHTML={{
                  __html: t(term.content, {
                    uu: 'ul',
                    sg: 'strong',
                  }),
                }}
                style={{
                  whiteSpace: 'pre-line',
                }}
                sx={{
                  color: 'quaternary',
                  opacity: '0.8',
                  mb: 10,
                }}
              />
            </div>
          ))}
        </Container>
      </Box>
    </>
  );
};

export default TermsAndConditions;
